import React, { Component } from "react";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import customFetch from "./apicall/api";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
      IPv4: null,
      latitude: null,
      longitude: null,
      email: "",
      username: "",
      phone: "",
      password: "",
      otp: "",
      errormsg: "",
      successmsg: "",
      errormsgphone: "",
      errormsgpassword: "",
      isEnable: true,
      isEnablePhone: true,
      canResend: false,
      isSpin: false,
      login_status: "",
      isLoginstatus: false,
      time: {
        minutes: 0,
        seconds: 0,
      },
      duration: 10 * 60 * 1000,
      timer: null,
      isViewpass: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlemailSubmit = this.handlemailSubmit.bind(this);
    this.handlephoneSubmit = this.handlephoneSubmit.bind(this);
    this.getAccesstoDevrev = this.getAccesstoDevrev.bind(this);
    this.startTimer = this.start.bind(this);
    this.setResendButtonTimeout = null;
  }

  componentDidMount() {
    document.title = "Login";
    // navigator.geolocation.getCurrentPosition(
    //   function(position) {
    //     this.setState({
    //       latitude: position.coords.latitude,
    //       longitude: position.coords.longitude,
    //     });
    //   }.bind(this)
    // );
    fetch("https://geolocation-db.com/json/")
      .then((response) => {
        return response.json();
      }, "jsonp")
      .then((res) => {
        console.log(res);
        this.setState({
          IPv4: res.IPv4,
          latitude: res.latitude,
          longitude: res.longitude,
        });
      })
      .catch((err) => console.log(err));
  }

  getAccesstoDevrev() {
    const requestOptions = {
      method: "GET",
      headers: {
        "api-token": localStorage.getItem("api_token"),
      },
    };
    customFetch(
      process.env.REACT_APP_URL +
        "/get/configuration/parameter/master?parameter_name=devrev_react_finops",
      requestOptions
    )
      .then((response) => {
        console.log('devrev res', response)
        return response.json();
      })
      .then((data) => {
        console.log('devrev data', data)
        Cookies.set('parameter_value', data.parameter_value, { expires: 7 });
      })
  }

  msToTime(duration) {
    let seconds = Math.floor((duration / 1000) % 60);
    let minutes = Math.floor((duration / (1000 * 60)) % 60);

    minutes = minutes.toString().padStart(2, "0");
    seconds = seconds.toString().padStart(2, "0");

    return {
      minutes,
      seconds,
    };
  }
  start() {
    if (!this.state.timer) {
      this.state.startTime = Date.now();
      this.timer = window.setInterval(() => this.run(), 10);
    }
  }
  run() {
    const diff = Date.now() - this.state.startTime;

    let remaining = this.state.duration - diff;
    if (remaining < 0) {
      remaining = 0;
    }
    this.setState(() => ({
      time: this.msToTime(remaining),
    }));
    if (remaining === 0) {
      window.clearTimeout(this.timer);
      this.timer = null;
      this.setState({ canResend: true });
    }
  }
  // resetTimer = () => {
  //   if(this.setResendButtonTimeout !== null) {
  //    this.setState({canResend:false})
  //     clearTimeout(this.setResendButtonTimeout)
  //   }
  //   this.setResendButtonTimeout = setTimeout(() => {
  //     this.setState({canResend:true})
  //   },2000)
  // }
  handleClick() {
    this.setState({
      show: !this.state.show,
    });
  }
  handleResendButtonClick = (e) => {
    e && e.preventDefault();
    //this.resetTimer()
    this.setState({ canResend: false });
    this.start();
    const formdata1 = new FormData();
    formdata1.append("user_email", this.state.email);
    customFetch(process.env.REACT_APP_URL + "/delotp", {
      method: "POST",
      body: formdata1,
    })
      .then((res1) => res1.json())
      .then((data1) => {
        console.log(data1.sendotp);
        if (data1.sendotp === true) {
          const formdata2 = new FormData();
          formdata2.append("user_email", this.state.email);
          customFetch(process.env.REACT_APP_URL + "/sendotp", {
            method: "POST",
            body: formdata2,
          })
            .then((res2) => res2.json())
            .then((data2) => {
              console.log(data2.sendotp);
              if (data2.sendotp === true) {
                this.setState({ successmsg: "OTP Send Success!" });
                //this.start()
                alert("OTP Send Success!");
              }
            })
            .catch((error) => console.log("error", error));
        }
      })
      .catch((error) => console.log("error", error));
  };

  handlephoneSubmit(event) {
    this.setState({ errormsg: "" });
    this.setState({ successmsg: "" });
    const { phone } = this.state;
    event.preventDefault();
    // AT-2143 | AT-2179 | Ankur Makavana | 12-07-2023 | conver post method to get method
    const params = {
      in_phone_no: phone,
    };
    const queryParams = new URLSearchParams(params).toString();
    customFetch(process.env.REACT_APP_URL + "/userexist?" + queryParams, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.userid);
        if (data.Valid === true) {
          const formdata1 = new FormData();
          formdata1.append("user_mobile_no", phone);
          customFetch(process.env.REACT_APP_URL + "/delotp", {
            method: "POST",
            body: formdata1,
          })
            .then((res1) => res1.json())
            .then((data1) => {
              console.log(data1.sendotp);
              if (data1.sendotp === true) {
                const formdata2 = new FormData();
                formdata2.append("user_mobile_no", phone);
                customFetch(process.env.REACT_APP_URL + "/sendotp", {
                  method: "POST",
                  body: formdata2,
                })
                  .then((res2) => res2.json())
                  .then((data2) => {
                    console.log(data2.sendotp);
                    if (data2.sendotp === true) {
                      console.log("OTP Send");
                      this.setState({ successmsg: "OTP Send Success!" });
                      alert("OTP Send Success!");
                      //  localStorage.setItem('in_userid', data2.in_userid);
                      //  localStorage.setItem('in_username', data2.in_username);
                      this.props.history.push({
                        pathname: "/Phoneotp",
                        state: {
                          phone: phone,
                          latitude: this.state.latitude,
                          longitude: this.state.longitude,
                          userid: data.userid,
                        },
                      });
                    }
                  })
                  .catch((error) => console.log("error", error));
              }
            })
            .catch((error) => console.log("error", error));
        }
      })
      .catch((error) => console.log("error", error));
  }
  handleSlideChange() {
    console.log("Slider Change");
    console.log(this.state.show);
    if (this.state.show === false) {
      this.setState({ errormsg: "" });
      this.setState({ successmsg: "" });
      const { username } = this.state;

      // AT-2143 | AT-2179 | Ankur Makavana | 12-07-2023 | conver post method to get method
      const params = {
        in_username: username,
      };
      const queryParams = new URLSearchParams(params).toString();

      customFetch(process.env.REACT_APP_URL + "/userexist?" + queryParams, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          //console.log(data.userid);
          if (data.Valid === true) {
            this.setState({ email: data.useremail });
            const formdata1 = new FormData();
            formdata1.append("user_email", data.useremail);
            customFetch(process.env.REACT_APP_URL + "/delotp", {
              method: "POST",
              body: formdata1,
            })
              .then((res1) => res1.json())
              .then((data1) => {
                console.log(data1.sendotp);
                if (data1.sendotp === true) {
                  const formdata2 = new FormData();
                  formdata2.append("user_email", data.useremail);
                  customFetch(process.env.REACT_APP_URL + "/sendotp", {
                    method: "POST",
                    body: formdata2,
                  })
                    .then((res2) => res2.json())
                    .then((data2) => {
                      console.log(data2.sendotp);
                      if (data2.sendotp === true) {
                        this.setState({ successmsg: "OTP Send Success!" });
                        this.start();
                        alert("OTP Send Success!");
                      }
                    })
                    .catch((error) => console.log("error", error));
                }
              })
              .catch((error) => console.log("error", error));
          } else {
            this.setState({ errormsg: "User Not Found", isEnable: true });
          }
        })
        .catch((error) => console.log("error", error));
    }
  }
  handlemailSubmit(event) {
    console.log("Form submit");
    this.setState({ errormsg: "", isSpin: true });
    this.setState({ successmsg: "" });
    const { email, password, otp, show, username } = this.state;
    event.preventDefault();
    // AT-2143 | AT-2179 | Ankur Makavana | 12-07-2023 | conver post method to get method
    // if (localStorage.getItem("access_token")) {
    //   fetch(process.env.REACT_APP_URL + "/new/logout", {
    //     method: "POST",
    //     headers: { "api-token": localStorage.getItem("access_token") },
    //   })
    //     .then((res) => res.json())
    //     .then((data) => {
    //       console.log(data.userid);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // }
    const params = {
      in_username: username,
    };
    const queryParams = new URLSearchParams(params).toString();

    customFetch(process.env.REACT_APP_URL + "/userexist?" + queryParams, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.userid);
        if (data.Valid === true) {
          const ftoken = localStorage.getItem("currentToken");
          const formdata2 = new FormData();
          localStorage.getItem("api_token") && formdata2.append("token", localStorage.getItem("api_token"))
          formdata2.append("in_username", username);
          formdata2.append("in_user_password", password);
          formdata2.append("in_client_ip", this.state.IPv4);
          formdata2.append("in_latitude", this.state.latitude);
          formdata2.append("in_longitude", this.state.longitude);
          formdata2.append(
            "in_gps_address",
            this.state.latitude + "," + this.state.longitude
          );
          if (otp !== "") {
            formdata2.append("otp_source", email);
            formdata2.append("otp", otp);
          }
          formdata2.append("in_userid", data.userid);
          formdata2.append("firebase_token", ftoken);
          formdata2.append("app_id", process.env.REACT_APP_APP_ID);
          customFetch(process.env.REACT_APP_URL + "/new/login", {
            method: "POST",
            body: formdata2,
          })
            .then((res2) => res2.json())
            .then((data2) => {
              console.log(data2.sendotp);
              if (data2.logged_in === true) {
                localStorage.setItem("in_userid", data2.in_userid);
                localStorage.setItem("in_username", data2.in_username);
                localStorage.setItem("employee_name", data2.employee_name);
                localStorage.setItem("api_token", data2.access_token);
                localStorage.setItem("refresh_token", data2.refresh_token);
                Cookies.set("refresh_token", data2.refresh_token);
                
                this.getAccesstoDevrev();
                
                const params = {
                  userid: data2.in_userid,
                };
                const queryParams = new URLSearchParams(params).toString();
                customFetch(process.env.REACT_APP_URL + "/getrole?" + queryParams, {
                  method: "GET",
                  headers: { "api-token": data2.access_token },
                })
                  .then((res) => res.json())
                  .then((data3) => {
                    console.log(
                      "effective_designation_id=>",
                      process.env.REACT_APP_effective_designation_id.includes(
                        data3.effective_designation_id
                      )
                    );
                    if (
                      process.env.REACT_APP_effective_designation_id.includes(
                        data3.effective_designation_id
                      )
                    ) {
                      localStorage.setItem(
                        "e_designation_id",
                        data3.effective_designation_id
                      );

                      this.props.history.push({
                        pathname: "/Dashboard",
                        state: {
                          api_token: data2.access_token,
                          branch_unit_level_id: data3.max_unit_level_id,
                          parent_entity_name: data3.parent_entity_name,
                          max_unit_level_id: data3.max_unit_level_id,
                          effective_designation_id:
                            data3.effective_designation_id,
                        },
                      });
                    } else {
                      this.setState({
                        isSpin: false,
                        errormsg: "User Not Authorised.",
                      });
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              } else {
                if (data2.login_status == "LI") {
                  localStorage.setItem("api_token", data2.access_token);
                  this.setState({
                    isLoginstatus: true,
                  });
                }
                this.setState({
                  errormsgpassword: data2.action_message,
                  isSpin: false,
                  login_status: data2.login_status,
                });
              }
            })
            .catch((error) => console.log("error", error));
        } else {
          this.setState({ errormsg: "User Not Found.", isSpin: false });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  clearlogin() {
    customFetch(process.env.REACT_APP_URL + "/new/logout", {
      method: "POST",
      headers: { "api-token": localStorage.getItem("access_token") },
      skipRefresh: true
    })
      .then((res) => res.json())
      .then((data) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
    if (event.target.name === "username") {
      const pattern = /([a-zA-Z0-9]+)/g;
      const result = pattern.test(event.target.value);
      //console.log("Email Valid:", result)
      if (result === true) {
        this.setState({ errormsg: "", isEnable: false });
      } else {
        this.setState({ errormsg: "Enter valid User Name", isEnable: true });
      }
    }
    if (event.target.name === "phone") {
      const pattern = /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/;
      if (pattern.test(event.target.value)) {
        if (event.target.value.length === 10) {
          this.setState({ errormsgphone: "", isEnablePhone: false });
        } else {
          this.setState({
            errormsgphone: "Enter valid Phone Number",
            isEnablePhone: true,
          });
        }
      } else {
        this.setState({
          errormsgphone: "Enter valid Phone Number",
          isEnablePhone: true,
        });
      }
      //console.log("Email Valid:", result)
    }
  }
  viewpass() {
    this.setState({ isViewpass: !this.state.isViewpass });
  }
  render() {
    const { isViewpass } = this.state;

    //console.log("123")

    return (
      <>
        {this.state.isSpin ? (
          <div className="preloader">
            <div className="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}
        <div
          className={
            this.state.isLoginstatus
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isLoginstatus: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{this.state.errormsgpassword}</p>
              <span
                className="btn btn-danger col-md-2"
                onClick={() => this.clearlogin()}
              >
                Logout
              </span>
            </div>
          </div>
        </div>
        <section className="main-sec">
          <div className="container fix-width">
            <div className="logo-area">
              <img src={require("../assets/images/aceN Logo.png")} alt="" />
            </div>
            <div className="login-text">
              <div className="login-title">
                <h2>Login Account</h2>
              </div>
              <div className="login-subtitle">
                Hello, welcome back to our account
              </div>
            </div>
            <div className="login-form-holder">
              <ul className="nav nav-pills justify-content-center">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    href="#email-login-area"
                    data-toggle="tab"
                    role="tab"
                  >
                    User Name
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#phone-login-area"
                    data-toggle="tab"
                    role="tab"
                  >
                    Phone Number
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div
                  className="tab-pane active"
                  id="email-login-area"
                  role="tabpanel"
                >
                  <div className="container">
                    <div className="email-login">
                      <form onSubmit={this.handlemailSubmit}>
                        <div className="form-group">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="email-label"
                          >
                            User Name
                          </label>
                          <input
                            name="username"
                            placeholder="User Name"
                            className="form-control email-box"
                            value={this.state.username}
                            onChange={this.handleChange}
                          />
                          <span className="email-error">
                            {this.state.errormsg}
                          </span>
                        </div>
                        <div className="form-group text-center">
                          <label className="p-1">Password</label>{" "}
                          <label className="switch">
                            <input
                              type="checkbox"
                              id="switch"
                              className="toggle_switch"
                              disabled={this.state.isEnable}
                              onClick={() => this.handleClick()}
                              onChange={() => this.handleSlideChange()}
                            />

                            <label className="checkbox-label" htmlFor="switch">
                              Toggle
                            </label>
                          </label>{" "}
                          <label className="p-1">OTP</label>
                        </div>

                        <div className="form-group">
                          {!this.state.show && (
                            // <input type="password" className="btn btn-lg btn-primary email-submit" id="exampleInputPassword1" placeholder="Password"/>
                            <>
                              <input
                                {...(isViewpass === true
                                  ? { type: "password" }
                                  : { type: "text" })}
                                name="password"
                                placeholder="Password"
                                className="form-control password-box"
                                value={this.state.password}
                                onChange={this.handleChange}
                              />
                              {isViewpass}
                              <img
                                src={require("../assets/images/view.png")}
                                className="view-pass"
                                onClick={() => this.viewpass()}
                              />
                              {this.state.login_status != "LI" && (
                                <span className="email-error">
                                  {this.state.errormsgpassword}
                                </span>
                              )}
                            </>
                          )}
                          {this.state.show && (
                            <>
                              <input
                                name="otp"
                                placeholder="OTP"
                                className="form-control email-box"
                                value={this.state.otp}
                                onChange={this.handleChange}
                              />
                              <div className="text-center">
                                <button
                                  className="btn btn-lg btn-primary email-submit"
                                  onClick={this.handleResendButtonClick}
                                  disabled={!this.state.canResend}
                                >
                                  {!this.state.canResend
                                    ? this.state.time.minutes +
                                      ":" +
                                      this.state.time.seconds
                                    : "Resend OTP"}
                                </button>
                              </div>
                            </>
                          )}
                        </div>

                        <div className="text-center">
                          <input
                            type="submit"
                            className="btn btn-lg btn-primary email-submit"
                            disabled={this.state.isEnable}
                            value="Login"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="tab-pane" id="phone-login-area" role="tabpanel">
                  <div className="container">
                    <div className="email-login">
                      <form onSubmit={this.handlephoneSubmit}>
                        <div className="form-group">
                          <label
                            htmlFor="exampleInputEmail11"
                            className="email-label"
                          >
                            Phone Number
                          </label>
                          <input
                            name="phone"
                            placeholder="Phone Number"
                            className="form-control email-box"
                            value={this.state.phone}
                            onChange={this.handleChange}
                          />
                          <span className="email-error">
                            {this.state.errormsgphone}
                          </span>
                        </div>
                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary otp-btn form-control"
                            disabled={this.state.isEnablePhone}
                          >
                            Request OTP
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withRouter(Login);
